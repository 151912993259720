$breakpoints: (
  xs: 1,
  sm: 1,
  md: 2,
  lg: 2,
  xl: 3,
  xxl: 3,
);
$margin: 30px;

.blogTiles {
  margin-left: -$margin/2;
  margin-right: -$margin/2;
  display: flex;
  justify-content: flex-start; //center;
  flex-wrap: wrap;

  margin-top: 2rem;
  margin-bottom: 2rem;

  &__loadMore {
    margin-bottom: 2rem;
    .thinBtn {
      width: 100%;
      display: block;
      color: $primary-color;
      cursor: pointer;
      &:hover,
      &:focus {
        color: black;
      }
    }
  }

  &--productView .blogTile {
    @include media-breakpoint-up(xxl) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  }
}

.blogTile {
  padding: $margin/2;

  @each $bp, $number in $breakpoints {
    @include media-breakpoint-up(#{$bp}) {
      flex: 0 0 100% / $number;
      max-width: 100% / $number;
    }
  }

  &__link {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    background: #fff;
    color: black;
    box-shadow: 1px 1px 5px 0px #e0e0e0;
    line-height: 1.1;
    text-align: left;
    padding: 8px;

    &:hover,
    &:focus {
      text-decoration: none;
      .thinBtn {
        color: black;
      }
    }
  }

  &__bg {
    @include bg-img();
    @include img-ratio(100%);
    width: 100%;
    z-index: -1;
  }

  &__content {
    width: 100%;
  }

  &__contentInner {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(#fff, 0.8);
  }

  &__title {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.2;
    margin: 0.9rem 0.2rem;
    text-align: center;
  }

  &__desc {
    font-size: 0.8rem;
    font-style: italic;
    height: 1.5rem;
    line-height: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: none;
  }
}

.thinBtn {
  font-size: 1rem;
  letter-spacing: 1px;
  transition: all 0.15s;
  width: 10em;
  padding: 0.5rem 1rem;
  text-align: center;
  margin: 1rem 0.5rem 0.5rem 0.5rem;
  color: $primary-color;
  font-weight: 700;
}
